
import { mapGetters } from 'vuex'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  computed: {
    ...mapGetters({
      getCategories: 'categories/getCategories',
    }),
  },
  methods: {
    convertToSlug,
  },
}
